<template>
    <div class="share-count-down" :class="`share-count-${type}`" v-if="number > 0">
        <span class="time-block">
            <span v-if="hours" class="number"> {{ hours }}</span>
            <span v-if="hours" class="fh">:</span>
            <span v-if="minutes" class="number">{{ minutes }}</span>
            <span v-if="minutes" class="fh">:</span>
            <span v-if="seconds" class="number">{{ seconds }}</span>
        </span>
        <span class="tips">后自动分账</span>
    </div>
</template>
<script>
export default {
    name: "ShareCountDownOne",
    props: {
        time: Number,
        type: String,
    },
    data() {
        return {
            number: 0,
            timerFlag: null,
            timeString: null,
            hours: 0,
            minutes: 0,
            seconds: 0,
        }
    },
    watch: {
        time() {
            this.init()
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.number = this.time
            
            if (this.timerFlag) clearInterval(this.timerFlag)
            
            this.countDown()
            
            this.timerFlag = setInterval(() => {
                this.countDown()
            }, 1000)
        },
        countDown() {
            
            this.seconds = this.padZero(this.number % 60);
            this.minutes = this.padZero(parseInt(this.number / 60) % 60);
            this.hours = this.padZero(parseInt(this.number / 60 / 60));
            
            this.number--
        },
        padZero(number) {
            
            if (number <= 0) return 0
            
            return number.toString().padStart(2, "0")
        }
    },
    destroyed() {
        if (this.timerFlag) clearInterval(this.timerFlag)
    }
}
</script>

<style scoped>

.time-block {
    height: 21px;
    background: #FBB03B;
    border-radius: 78px 78px 78px 78px;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 21px;
    margin-right: 4px;
    padding-left: 6px;
    padding-right: 6px;
    display: inline-block;
}

.share-count-down {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 56px 56px 56px 56px;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 21px;
    text-align: left;
    font-weight: 400;
    height: 21px;
    overflow: hidden;
    display: inline-block;
    padding-right: 4px;
}

.share-count-one {
    background-color: transparent;
    height: 32px;
    width: auto;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    
    .tips {
        font-size: 12px;
        color: #FBB03B;
        line-height: 14px;
        margin-left: 8px;
    }
    
    .time-block {
        margin-right: 0;
        background-color: transparent;
        height: 32px;
        width: auto;
        padding: 0 0 0 0;
        border-radius: 0;
        display: flex;
        align-items: center;
        
        .fh {
            display: block;
            margin-right: 4px;
            margin-left: 4px;
            font-size: 20px;
            color: #333333;
        }
        
        .number {
            background: #FBB03B;
            border-radius: 0;
            width: 32px;
            height: 32px;
            font-size: 18px;
            line-height: 32px;
            text-align: center;
            display: block;
        }
    }
}

</style>